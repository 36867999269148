/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { IsSentoAdmin, usePermissionChecker } from '../../../../app/hooks/PermissionChecker'
import { LIST_PERMISSIONS, SENTO } from '../../../../app/constants/common'
import { IAccount } from '../../../../app/modules/auth/models/AccountModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
export function AsideMenuMain() {
  const intl = useIntl()
  const { hasPermission } = usePermissionChecker();
  const Sento = IsSentoAdmin();
  return (
    <>
      {hasPermission(LIST_PERMISSIONS.DASHBOARD) && (
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
      )}
      {hasPermission(LIST_PERMISSIONS.PRODUCTS) && (
        <>
          <AsideMenuItem
            to='/products'
            icon='/media/icons/duotune/abstract/abs027.svg'
            title={intl.formatMessage({ id: 'MENU.PRODUCTS' })}
            fontIcon='bi-app-indicator'
          />
          {Sento && (
            <AsideMenuItemWithSub
            to='/sales-products'
            icon='/media/icons/duotune/ecommerce/ecm006.svg'
            title={'Sản phẩm - sales'}
            fontIcon='bi-app-indicator'
          >
            <AsideMenuItem
              to='/sales-products'
              title={'Sản phẩm - sales'}
              hasBullet
            />
            <AsideMenuItem
              to='/sales-products/add'
              title='Thêm sản phẩm'
              hasBullet
            />
          </AsideMenuItemWithSub>
          )}
        </>
      )}
      {hasPermission(LIST_PERMISSIONS.ORDERS) && (

        <AsideMenuItemWithSub
          to='/orders'
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
          title={intl.formatMessage({ id: 'MENU.ORDER' })}
          fontIcon='bi-app-indicator'
        >
          <AsideMenuItem
            to='/orders'
            title={intl.formatMessage({ id: 'MENU.ORDER' })}
            hasBullet
          />
          <AsideMenuItem
            to='/orders/product-search'
            title='Tìm kiếm sản phẩm'
            hasBullet
          />
        </AsideMenuItemWithSub>
      )}
      {hasPermission(LIST_PERMISSIONS.SUPPLIERS) && (
        <AsideMenuItem
          to='/suppliers'
          icon='/media/icons/duotune/abstract/abs027.svg'
          title={intl.formatMessage({ id: 'MENU.SUPPLIERS' })}
          fontIcon='bi-app-indicator'
        />
      )}
      {hasPermission(LIST_PERMISSIONS.AGENTS) && (
        <AsideMenuItem
          to='/agents'
          icon='/media/icons/duotune/communication/com005.svg'
          title={intl.formatMessage({ id: 'MENU.AGENT' })}
          fontIcon='bi-app-indicator'
        >

          {/*<AsideMenuItem to='/customer/add' title={intl.formatMessage({id: 'MENU.LIST'})} hasBullet={true}/>*/}
          {/*<AsideMenuItem to='/customer/group' title={intl.formatMessage({id: 'MENU.CUSTOMER_GROUP'})} hasBullet={true}/>*/}
          {/*<AsideMenuItem to='/customer/c' title={intl.formatMessage({id: 'MENU.PRICE_LIST'})} hasBullet={true}/>*/}
        </AsideMenuItem>
      )}
      {hasPermission(LIST_PERMISSIONS.WAREHOUSE) && (
        <AsideMenuItemWithSub
          to='/warehouse'
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
          title={intl.formatMessage({ id: 'MENU.WAREHOUSE_MANAGEMENT' })}
          fontIcon='bi-app-indicator'
        >
          <AsideMenuItem to='/warehouse/cart' title={intl.formatMessage({ id: 'MENU.CART' })} hasBullet />
          <AsideMenuItem to='/warehouse/warranty_refund' title={intl.formatMessage({ id: 'MENU.WARRANTY_REFUND' })} hasBullet />
          <AsideMenuItem to='/warehouse/list' title={intl.formatMessage({ id: 'MENU.WAREHOUSE' })} hasBullet />
          <AsideMenuItemWithSub
            to='/warehouse/receipt'
            icon='/media/icons/duotune/ecommerce/ecm006.svg'
            title="Đơn nhập hàng"
            fontIcon='bi-app-indicator'
          >
            <AsideMenuItem to='/warehouse/receipt/list' title={intl.formatMessage({ id: 'MENU.RECEIPT' })} hasBullet />
            <AsideMenuItem to='/warehouse/receipt/find-products' title={'Tìm kiếm sản phẩm'} hasBullet />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/warehouse/inventory-delivery'
            icon='/media/icons/duotune/ecommerce/ecm006.svg'
            title="Đơn xuất hàng"
            fontIcon='bi-app-indicator'
          >
            <AsideMenuItem to='/warehouse/inventory-delivery/list' title={'Đơn hàng xuất kho'} hasBullet />
            <AsideMenuItem to='/warehouse/inventory-delivery/find-products' title={'Tìm kiếm sản phẩm'} hasBullet />
          </AsideMenuItemWithSub>
          <AsideMenuItem to='/warehouse/inventory-check-sheet' title={intl.formatMessage({ id: 'MENU.INVENTORY_CHECK_SHEET' })} hasBullet />
        </AsideMenuItemWithSub>
      )}
      {hasPermission(LIST_PERMISSIONS.ABILITIES) && (
        <AsideMenuItemWithSub
          to='/abilities'
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
          title={intl.formatMessage({ id: 'MENU.ABILITIES' })}
          fontIcon='bi-app-indicator'
        >
          <AsideMenuItem to='/abilities/receipt-voucher' title={intl.formatMessage({ id: 'MENU.RECEIPT_VOUCHER' })} hasBullet />
          <AsideMenuItem to='/abilities/payment-voucher' title={intl.formatMessage({ id: 'MENU.PAYMENT_VOUCHER' })} hasBullet />
          <AsideMenuItem to='/abilities/index' title={intl.formatMessage({ id: 'MENU.ABILITIES' })} hasBullet />
        </AsideMenuItemWithSub>
      )}
      {hasPermission(LIST_PERMISSIONS.DEBTS) && (
        <AsideMenuItemWithSub
          to='/debt'
          icon='\media\icons\duotune\finance\fin010.svg'
          title={intl.formatMessage({ id: 'MENU.DEBT' })}
          fontIcon='bi-app-indicator'
        >
          <AsideMenuItem to='/debt/agency' title={intl.formatMessage({ id: 'MENU.DEBT.AGENCY' })} icon='\media\icons\duotune\general\gen001.svg' />
          <AsideMenuItem to='/debt/supplier' title={intl.formatMessage({ id: 'MENU.DEBT.SUPPLIER' })} icon='\media\icons\duotune\ecommerce\ecm006.svg' />
          <AsideMenuItem to='/debt/sales-report' title='Báo cáo doanh thu' icon='\media\icons\duotune\ecommerce\ecm006.svg' />
        </AsideMenuItemWithSub>
      )}
      {(hasPermission(LIST_PERMISSIONS.ACCOUNTS) && Sento) && (
        <AsideMenuItemWithSub
          to='/accounts'
          icon='/media/icons/duotune/general/gen051.svg'
          title={intl.formatMessage({ id: 'MENU.ACCOUNT' })}
          fontIcon='bi-app-indicator'
        >
          <AsideMenuItem to='/accounts/list' title={intl.formatMessage({ id: 'MENU.ACCOUNT.LIST' })} icon='\media\icons\duotune\communication\com005.svg' />
          {Sento && (
            <AsideMenuItem to='/accounts/roles' title={intl.formatMessage({ id: 'MENU.ROLE.LIST' })} icon='\media\icons\duotune\coding\cod001.svg' />
          )}
        </AsideMenuItemWithSub>
      )}
      {(hasPermission(LIST_PERMISSIONS.STORES) && Sento) && (
        <AsideMenuItem
          to='/stores'
          icon='/media/icons/duotune/ecommerce/ecm004.svg'
          title={intl.formatMessage({ id: 'MENU.STORE' })}
          fontIcon='bi-app-indicator'
        />
      )}
      
      {(hasPermission(LIST_PERMISSIONS.ACCOUNTS) && Sento )&& (
        <AsideMenuItem
          to='/auth-logs'
          icon='/media/icons/duotune/ecommerce/ecm004.svg'
          title='Lịch sử đăng nhập'
          fontIcon='bi-app-indicator'
        />
      )}
      
    </>
  )
}
